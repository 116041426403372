import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faCookieBite } from '@fortawesome/pro-regular-svg-icons';

import { CookieConsentOption } from '@constants/types';
import { useSitelinks } from '@hooks/useSiteLinks';

import styles from './CookieDialog.module.scss';
import Props from './CookieDialog.props';
import Button from '@components/ui/Button/Button';
import TextLink from '@components/ui/TextLink/TextLink';

const CookieDialog = ({ className }: Props) => {
  const { t } = useTranslation();
  const siteLinks = useSitelinks();
  const [consent, setConsent] = useCookies(['CookieConsent']);
  const [visible, setVisible] = useState(false);
  const [isCookieVisible, setCookieVisible] = useState(false);
  const [cookies, setCookies] = useState<CookieConsentOption[]>([
    {
      id: 'NECESSARY',
      name: t('DOMAIN.COOKIES.OPTIONS.NECESSARY.TITLE'),
      checked: true,
      visible: false,
      disabled: true,
      desc: t('DOMAIN.COOKIES.OPTIONS.NECESSARY.DESCRIPTION'),
    },

    {
      id: 'PREFERENCES',
      name: t('DOMAIN.COOKIES.OPTIONS.PREFERENCES.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.PREFERENCES.DESCRIPTION'),
    },

    {
      id: 'STATISTICS',
      name: t('DOMAIN.COOKIES.OPTIONS.STATISTICS.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.STATISTICS.DESCRIPTION'),
    },

    {
      id: 'MARKETING',
      name: t('DOMAIN.COOKIES.OPTIONS.MARKETING.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.MARKETING.DESCRIPTION'),
    },
  ]);

  useEffect(() => {
    if (consent.CookieConsent) {
      const dataLayer = Object.keys(consent.CookieConsent).reduce(
        (dataLayer, key) =>
          `${dataLayer}window.dataLayer.push({event:"${key}",${key}:${consent.CookieConsent[key]}});`,
        '',
      );

      const HTML = `window.dataLayer = window.dataLayer||[];${dataLayer}`;

      const script = document.createElement('script');
      script.innerHTML = HTML;

      document.body.prepend(script);
    } else {
      setCookieVisible(true);
    }
  }, [consent]);

  const toggleCookies = () => {
    setVisible((v) => !v);
  };

  const toggleCookie = (cookie: CookieConsentOption) => {
    if (!cookie.disabled) {
      const newCookies = [...cookies];
      const item = newCookies.find((c: CookieConsentOption) => c.id === cookie.id);

      if (item) item.checked = !item.checked;

      setCookies(newCookies);
    }
  };

  const toggleCookieSummary = (cookie: CookieConsentOption) => {
    const newCookies = [...cookies];
    const item = newCookies.find((c: CookieConsentOption) => c.id === cookie.id);

    if (item) item.visible = !item.visible;

    setCookies(newCookies);
  };

  const getCookies = () => (
    <div
      className={cn([styles.DialogCookies], {
        [styles.CookiesVisible]: visible,
      })}>
      {cookies.map((cookie) => {
        return (
          <div
            key={cookie.id}
            className={cn([styles.Cookie], {
              [styles.CookieActive]: cookie.visible,
            })}>
            <div className={styles.CookieHeader}>
              <div className={styles.CookieCheckbox}>
                <input
                  type="checkbox"
                  id={cookie.id}
                  value={cookie.id}
                  disabled={cookie.disabled}
                  checked={cookie.checked}
                  onChange={() => {}}
                />

                <label htmlFor={cookie.id} onClick={() => toggleCookie(cookie)}>
                  <FontAwesomeIcon icon={faCheck} />
                </label>
              </div>

              <div className={styles.CookieTitle} onClick={() => toggleCookieSummary(cookie)}>
                {cookie.name}
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>

            <div className={styles.CookieSummary}>{cookie.desc}</div>
          </div>
        );
      })}
    </div>
  );

  const confirm = () => {
    const myCookies = Object.fromEntries(
      cookies.map<[string, boolean]>(({ id, checked }) => [id, checked]),
    );

    const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setConsent('CookieConsent', JSON.stringify(myCookies), { expires: date });
    setCookieVisible(false);
  };

  /**
   * The user has not accepted the CookieConsent yet, so we should show the
   * popup with all the cookie's information.
   */

  if (!isCookieVisible) return null;

  return (
    <div className={cn(styles.CookieDialog, className)} role="alertdialog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className={styles.DialogTitle}>
              <FontAwesomeIcon icon={faCookieBite} />
              {t('DOMAIN.COOKIES.COOKIES_ON_SITE')}
            </h4>

            <div className={styles.DialogContent}>
              {`${t('DOMAIN.COOKIES.MESSAGE')} `}
              <TextLink href={`/${siteLinks?.privacy_statement?.[0]._slug}`} openInNewTab>
                {t('GENERIC.PRIVACY_STATEMENT')}
              </TextLink>
              {` ${t('GENERIC.AND')} `}
              <TextLink href={`/${siteLinks?.cookie_statement?.[0]._slug}`} openInNewTab>
                {t('GENERIC.COOKIE_STATEMENT')}
              </TextLink>
              .
            </div>

            {getCookies()}

            <div className={styles.DialogButtons}>
              <Button
                className={styles.DialogButton}
                onPress={toggleCookies}
                theme="primary-inverted">
                {t('DOMAIN.COOKIES.CHANGE_SETTINGS')}
              </Button>

              <Button className={styles.DialogButton} onPress={confirm}>
                {t('GENERIC.ACCEPT')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieDialog;
