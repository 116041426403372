import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useLocale } from '@hooks/index';

import Props from './Meta.props';
import { EN_DOMAIN, EN_SHAREAHOLIC_ID, NL_DOMAIN, NL_SHAREAHOLIC_ID } from '@constants/config';

export const Meta = ({ title, description, image }: Props) => {
  const router = useRouter();
  const locale = useLocale();

  const siteId = locale === 'nl-NL' ? NL_SHAREAHOLIC_ID : EN_SHAREAHOLIC_ID;

  const getFullPath = () => {
    const isDP = locale === 'nl-NL';
    const fullUrl = `${isDP ? NL_DOMAIN : EN_DOMAIN}${router.asPath}`;

    return fullUrl;
  };

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={getFullPath()} />
      <meta name="og:type" content="website" />

      {!!image && <meta name="og:image" content={image} />}
      {!!image && <meta name="og:image:secure_url" content={image} />}
      {!!image && <meta name="og:image:width" content="650" />}
      {!!image && <meta name="og:image:height" content="300" />}

      <meta name="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      {image && <meta name="twitter:image" content={image} />}

      <meta name="shareaholic:site_id" content={siteId} />
    </Head>
  );
};
