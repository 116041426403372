import React from 'react';
import cn from 'classnames';
import Image from 'next/image';

import styles from './IconHeader.module.scss';
import Props from './IconHeader.props';

const IconHeader = ({ title, icon, size = 'sm', className, titleType = 'h2' }: Props) => {
  return (
    <div className={cn(styles.IconHeader, styles[size], className)}>
      <div className={styles.Icon}>
        <Image src={icon} alt="" quality={60} layout="fill" />
      </div>
      {titleType === 'h1' && <h1 className={styles.Title}>{title}</h1>}
      {titleType === 'h2' && <h2 className={styles.Title}>{title}</h2>}
    </div>
  );
};

export default IconHeader;
