import React from 'react';
import cn from 'classnames';

import styles from './ListWrapper.module.scss';
import Props from './ListWrapper.props';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import Button from '@components/ui/Button/Button';
import IconHeader from '@components/ui/IconHeader/IconHeader';

const ListWrapper = ({ link, children, hideTitle, className, ...IconHeaderProps }: Props) => {
  return (
    <div className={cn(styles.ListWrapper, className)}>
      {!hideTitle && <IconHeader className={styles.Header} {...IconHeaderProps} />}
      <div className={cn({ [styles.Content]: !!link })}>{children}</div>
      {!!link && (
        <Button
          href={link.url}
          onPress={link.onClick}
          className={styles.Button}
          icon={faLongArrowRight}
          size="md">
          {link.label}
        </Button>
      )}
    </div>
  );
};

export default ListWrapper;
